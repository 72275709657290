import { marked } from 'marked'
import createDOMPurify from 'dompurify'

export default {
  data () {
    return {
      domPurify: null
    }
  },
  created () {
    this.domPurify = createDOMPurify()
  },
  methods: {
    parseMarkdown (markdownString) {
      return this.domPurify.sanitize(marked.parse(markdownString, {
        headerIds: false,
        breaks: true
      }))
    }
  }
}
